<template>
  <div class="AccessVisualizationClientFactureProfile">

    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      color="black">
    </loading>

    <CRow>
      <CCol>
        <h1><CBadge color="primary"><CIcon name="cil-center-focus" size="lg"/>  Visualisation - Que voit mon client ?</CBadge> </h1>
        <h1 class="text-dark"> Facture #{{facture.facture_number}} - {{facture.mission.name}} </h1>
      </CCol>
    </CRow>

    <CCard class="mt-3">
      <CCardBody>
        <CRow>
          <CCol sm="4">
            <span> # Facture : </span> <strong> {{facture.facture_number}}</strong><br>
            <span> Mission : </span> <strong> {{facture.mission.name}}</strong><br>
          </CCol>

          <CCol class="text-right" sm="4">
            <span> MONTANT TTC </span><br>
            <strong style="font-size: 1.6em;"> {{formatThousandSeparatorNumber(parseFloat(this.facture.montant_total_ttc).toFixed(2))}} €</strong>
          </CCol>

          <CCol class="text-right" sm="4">
            <span> RESTE A PAYER </span><br>
            <strong style="font-size: 1.6em;"> {{formatThousandSeparatorNumber(parseFloat(parseFloat(this.facture.montant_total_ttc).toFixed(2) - parseFloat(this.facture.montant_total_paid).toFixed(2)).toFixed(2))}} €</strong>
          </CCol>

        </CRow>
        <CRow v-if="parseFloat(this.facture.montant_total_ht) == 0" class="mt-2">
          <CCol class="text-right">
            <strong class="text-danger">Facture en cours de création</strong>
          </CCol>
        </CRow>

        <CRow v-else class="mt-1 mb-3">
          <CCol>
            <CButton shape="pill" color="outline-primary" @click="downloadFacture()">
              <CIcon name="cil-cloud-download"/> Télécharger votre facture en pdf
            </CButton>
          </CCol>
        </CRow>

        <span> Adresse de facturation : </span><strong>{{facture.facturation_address}}</strong>

        <CRow >
          <CCol sm="6">
            <span> Modalités de paiement possibles : </span>
              <strong v-if="facture.is_modalites_virement">Virement </strong>
              <strong v-if="facture.is_modalites_cb">Carte bancaire </strong>
              <strong v-if="facture.is_modalites_cash">Liquide </strong>
              <strong v-if="facture.is_modalites_cheque">Chèque </strong>
          </CCol>
          <CCol sm="6">
            <span> Délais de paiement : </span>
            <strong v-if="facture.delai_paiement != 'RECEP'">{{facture.delai_paiement}} jours</strong>
            <strong v-else>A réception</strong>
          </CCol>
        </CRow>

        <CRow>
          <CCol sm="6">
            <span> Date de facturation : </span><strong>{{$dayjs(facture.date_facturation).format('DD/MM/YYYY')}}</strong>
          </CCol>
          <CCol sm="6">
            <span> Date d'échéance  : </span><strong>{{$dayjs(facture.date_echeance).format('DD/MM/YYYY')}}</strong>
          </CCol>
        </CRow>

        <CRow v-if="facture.message_facture">
          <CCol>
            Message personnalisé : {{facture.message_facture}}
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>

    <CCard>
      <CCardHeader>
        <strong>Paiements réalisés à date</strong>
      </CCardHeader>
      <CCardBody>
        <CRow>
          <CCol>
            <CDataTable
              headVariant="light"
              :items="paiementsRender"
              :fields="paiementsRenderFields"
              :noItemsView='{ noResults: "Aucun résultat", noItems: "Aucun résultat" }'
            >

            <template #date="{item}">
              <td>
                {{$dayjs(item.date).format('DD/MM/YYYY')}}
              </td>
            </template>

            <template #montant_ttc="{item}">
              <td>
                {{formatThousandSeparatorNumber(parseFloat(item.montant_ttc).toFixed(2))}} €
              </td>
            </template>

            <template #modalites="{item}">
              <td>
                {{modalitesPaiementRender(item.modalites)}}
              </td>
            </template>

            <template #actions="{item}">
              <td>
                <CButtonGroup>
                  <CButton
                    color="primary"
                    variant="outline"
                    square
                    size="sm"
                    :disabled="!facture.is_active"
                    @click="downloadPaiement(item)"
                  >
                    Editer le reçu
                  </CButton>
                </CButtonGroup>
              </td>
            </template>

            <template #no-items-view>
              <CRow>
                <CCol class="text-center">
                    Aucun paiement encore réalisé
                </CCol>
              </CRow>
            </template>
            </CDataTable>
          </CCol>
        </CRow>

        <CRow>
          <CCol class="text-right" style="font-size: 1.4em;">
            <CRow class="mt-2">
              <CCol sm="10">
                <strong> Total payé</strong>
              </CCol>
              <CCol sm="2">
                <strong>{{formatThousandSeparatorNumber(parseFloat(this.facture.montant_total_paid).toFixed(2))}} €</strong>
              </CCol>
            </CRow>
            <CRow class="mt-2">
              <CCol sm="10">
                <strong> Reste à payer</strong>
              </CCol>
              <CCol sm="2">
                <strong>{{formatThousandSeparatorNumber(parseFloat(parseFloat(this.facture.montant_total_ttc).toFixed(2) - parseFloat(this.facture.montant_total_paid).toFixed(2)).toFixed(2))}} €</strong>
              </CCol>
            </CRow>
          </CCol>
        </CRow>

      </CCardBody>
    </CCard>

  </div>
</template>

<script>
import renderMixins from '@/mixins/renderMixins'

import { APIManagerConnected } from '@/api/APIManagerConnected'

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

const apiManagerConnected = new APIManagerConnected()

export default {
  name: 'AccessVisualizationClientFactureProfile',
  components: {
    Loading,
  },
  mixins: [
    renderMixins
  ],
  data: function () {
    return {
      // ----------- GETTERS -------------
      isFactureLoading: false,
      facture: {
        id: 0,
        facture_number: '',
        facturation_address: '',
        delai_paiement: '30',
        date_facturation: '',
        date_echeance: '',
        is_acompte: false,
        is_modalites_virement: false,
        is_modalites_cb: false,
        is_modalites_cash: false,
        is_modalites_cheque: false,
        message_facture: '',
        is_active: true,
        mission: {
          id: 0,
          name: '',
          client: '',
          type: '',
          state: '',
          montant_ht: '',
          starting_date: '',
          ending_date: '',
          description: '',
          is_active: '',
          created_at: '',

          contact: {
            id: 0,
            first_name: '',
            last_name: '',
            full_name: '',
          },
          referent: {
            id: 0,
            full_name: '',
            user: {
              id: 0,
              first_name: '',
              last_name: '',
              username: ''
            }
          },
          collaborateurs: []
        },
      },


      // ---------------- PAIEMENTS ------------
      paiements: [],
      paiementsRender: [],
      paiementsRenderFields: [
        { key: "date", label: "Date", _style: "min-width: 100px; font-size: 14px"},
        { key: "modalites", label: "Modalités de paiement", _style: "min-width: 150px; font-size: 14px"},
        { key: "montant_ttc", label: "Montant", _style: "min-width: 100px; font-size: 14px"},
        { key: "actions", label: "Actions", _style: "min-width: 150px; font-size: 14px"},
      ],
      isPaiementsLoading: false,

    }
  },
  computed: {
    token () {
      return this.$store.state.auth.token
    },
    isLoading () {
      if (this.isFactureLoading || this.isPaiementsLoading) {
        return true
      }
      return false
    },
  },


  created: function() {
    this.$dayjs.locale('fr')
    this.getFacture()
    this.getAllFacturePaiements()
  },
  watch: {
    paiements: function (newPaiements) {
      if (newPaiements.length == 0) {
        this.paiementsRender = []
      }
      else {
        var final_array = []
        for (var i = 0; i < newPaiements.length; i++) {
          final_array.push(
            {
              'id': newPaiements[i].id,
              'date': newPaiements[i].date,
              'modalites': newPaiements[i].modalites,
              'montant_ttc': newPaiements[i].montant_ttc,
              'client_name': newPaiements[i].client_name,
            }
          )
        }
        this.paiementsRender = final_array
      }
    },
  },


  methods: {
    // ------------- Getters --------------
    getFacture () {
      this.isFactureLoading = true
      apiManagerConnected.getFacture(this.token, this.$route.params.id)
      .then((result) => {
        this.facture = result.data
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.isFactureLoading = false
      })
    },


    getAllFacturePaiements () {
      this.isPaiementsLoading = true
      apiManagerConnected.getAllFacturePaiements(this.token, this.$route.params.id)
      .then((result) => {
        this.paiements = result.data
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.isPaiementsLoading = false
      })
    },


    downloadFacture () {
      apiManagerConnected.downloadFacture(this.token, this.$route.params.id)
      .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          const factureName = 'Facture-' + this.facture.facture_number + '-' + this.facture.mission.client.name  + '-' + this.facture.date_facturation + '.pdf'
          link.setAttribute('download', factureName); //or any other extension
          document.body.appendChild(link);
          link.click();
        }, () => {
          this.$store.commit('openGlobalErrorModal')
        })
    },

    downloadPaiement (paiement) {
      apiManagerConnected.downloadPaiement(this.token, paiement.id)
      .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          const paiementName = 'Recu-' + paiement.date + '-' + paiement.client_name + '.pdf'
          link.setAttribute('download', paiementName); //or any other extension
          document.body.appendChild(link);
          link.click();
        }, () => {
          this.$store.commit('openGlobalErrorModal')
        })
    },
  }
}
</script>
